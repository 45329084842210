var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "VolunteerForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "志愿者信息" } },
                [
                  _c("el-form-item", { attrs: { label: "姓名" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.echoData.volunteerName))]),
                  ]),
                  _c("el-form-item", { attrs: { label: "性别" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.sexText))]),
                  ]),
                  _c("el-form-item", { attrs: { label: "手机号" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.echoData.phone))]),
                  ]),
                  _c("el-form-item", { attrs: { label: "出生日期" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.echoData.birthday))]),
                  ]),
                  _c("el-form-item", { attrs: { label: "所属项目" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.echoData.communityName))]),
                  ]),
                  _c("el-form-item", { attrs: { label: "服务大类" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.echoData.volunteerCat))]),
                  ]),
                  _c("el-form-item", { attrs: { label: "参与岗位" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.echoData.volunteerDuty))]),
                  ]),
                  _c("el-form-item", { attrs: { label: "个人擅长" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.echoData.adept))]),
                  ]),
                  _c("el-form-item", { attrs: { label: "志愿者描述" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.echoData.introduce))]),
                  ]),
                  !_vm.look
                    ? [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "审核状态",
                              rules: [
                                {
                                  required: true,
                                  message: "请选择审核状态",
                                  trigger: "change",
                                },
                              ],
                              prop: "status",
                            },
                          },
                          [
                            _c("v-select", {
                              attrs: {
                                options: _vm.statusOps,
                                width: _vm.width,
                              },
                              model: {
                                value: _vm.form.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "status", $$v)
                                },
                                expression: "form.status",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.status === 2,
                                expression: "form.status === 2",
                              },
                            ],
                            attrs: {
                              label: "拒绝原因",
                              rules: [
                                {
                                  required: true,
                                  message: "拒绝原因不允许为空",
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c("v-textarea", {
                              attrs: { maxlength: 200 },
                              model: {
                                value: _vm.form.rejectReason,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "rejectReason", $$v)
                                },
                                expression: "form.rejectReason",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.form.status === 0 ||
                                  _vm.form.status === 1,
                                expression:
                                  "(form.status === 0) || (form.status === 1)",
                              },
                            ],
                            attrs: { label: "拒绝原因" },
                          },
                          [
                            _c("v-textarea", {
                              attrs: { maxlength: 200 },
                              model: {
                                value: _vm.form.rejectReason,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "rejectReason", $$v)
                                },
                                expression: "form.rejectReason",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.look
                    ? [
                        _c("el-form-item", { attrs: { label: "审核状态" } }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.statusMap[_vm.form.status])),
                          ]),
                        ]),
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.status === 2,
                                expression: "form.status === 2",
                              },
                            ],
                            attrs: { label: "拒绝原因" },
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.form.rejectReason))])]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }