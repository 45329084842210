<template>
  <div class="VolunteerForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
        <col2-block title="志愿者信息">
          <el-form-item label="姓名">
            <span>{{echoData.volunteerName}}</span>
          </el-form-item>
          <el-form-item label="性别">
            <span>{{sexText}}</span>
          </el-form-item>
          <el-form-item label="手机号">
            <span>{{echoData.phone}}</span>
          </el-form-item>
          <el-form-item label="出生日期">
            <span>{{echoData.birthday}}</span>
          </el-form-item>
          <el-form-item label="所属项目">
            <span>{{echoData.communityName}}</span>
          </el-form-item>
          <el-form-item label="服务大类">
            <span>{{echoData.volunteerCat}}</span>
          </el-form-item>
          <el-form-item label="参与岗位">
            <span>{{echoData.volunteerDuty}}</span>
          </el-form-item>
          <el-form-item label="个人擅长">
            <span>{{echoData.adept}}</span>
          </el-form-item>
          <el-form-item label="志愿者描述">
            <span>{{echoData.introduce}}</span>
          </el-form-item>
          <template v-if="!look">
          <el-form-item label="审核状态" :rules="[{ required: true, message: '请选择审核状态', trigger: 'change' }]" prop="status">
            <v-select v-model="form.status" :options="statusOps" :width="width" />
          </el-form-item>
          <el-form-item v-show="form.status === 2" label="拒绝原因" :rules="[{ required: true, message: '拒绝原因不允许为空', trigger: 'blur' }]">
            <v-textarea v-model="form.rejectReason" :maxlength="200"></v-textarea>
          </el-form-item>
          <el-form-item v-show="(form.status === 0) || (form.status === 1)" label="拒绝原因">
            <v-textarea v-model="form.rejectReason" :maxlength="200"></v-textarea>
          </el-form-item>
          </template>
          <template v-if="look">
            <el-form-item label="审核状态">
            <span>{{statusMap[form.status]}}</span>
          </el-form-item>
          <el-form-item label="拒绝原因" v-show="form.status === 2">
            <span>{{form.rejectReason}}</span>
          </el-form-item>
          </template>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>
<script>
import { editURL, queryURL } from './api'
import { setStatusOps,statusMap } from './map'
import { Col2Detail, Col2Block } from 'components/bussiness'

export default {
  name: 'VolunteerForm',
  components: {
    Col2Detail,
    Col2Block
  },
  data () {
    return {
      width: 182,
      statusOps: setStatusOps(),
      statusMap,
      form: {
        status: undefined,
        rejectReason: ''
      },
      echoData: {
        volunteerName: '',
        phone: '',
        birthday: '',
        echoData: '',
        communityName: '',
        volunteerCat: '',
        volunteerDuty: '',
        adept: '',
        introduce: ''
      },
      sexText: '',
      submitConfig: {
        queryUrl: queryURL,
        submitUrl: '',
        queryMethod: 'get',
        submitContentType: 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      look: ''
    }
  },
  mounted () {
    const { id,look } = this.$route.query
    if (id !== undefined) {
      this.look = look
      if(look){
        this.$setBreadcrumb('查看')
      } else {
        this.$setBreadcrumb('编辑')
        this.submitConfig.submitUrl = editURL
      }    
      this.$refs.formPanel.getData({ id })
    }
  },
  methods: {
    update (data) {
      this.form.volunteerId = data.volunteerId
      this.form.userId = data.userId
      this.form.dataObject = data.volunteerName + '/' + data.phone
      this.form.status = Number(data.status)
      this.sexText = data.sex === '1' ? '男' : '女'
      Object.keys(this.echoData).forEach(key => {
        this.echoData[key] = data[key]
      })
      this.phone = data.phone
      if (data.rejectReason) {
        this.form.rejectReason = data.rejectReason
      }
    },
    submitBefore (data) {
      if (data.status === 1 || data.status === 0) {
        if (!data.rejectReason) {
          delete data.rejectReason
        }
      } else {
        if (!data.rejectReason) {
          alert('请输入拒绝原因!')
          return false
        }
      }
      return true
    }
  }
}
</script>
